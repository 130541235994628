import React from "react";

const TechnicalSpecifications = ({ specs }) => {
  const half = Math.ceil(specs.length / 2);

  const firstHalf = specs.slice(0, half);
  const secondHalf = specs.slice(half);

  return (
    <section className="bg-[#303030] pb-14 pt-10 md:pt-16">
      <div className="container">
        <h3 className="text-4 mb-10 text-center text-white md:mb-16">Especificaciones técnicas</h3>
      </div>

      <div className="container">
        <div className="row max-w-7xl text-white lg:!mx-auto">
          <div className="text-white lg:col-6">
            {firstHalf.map((data) => (
              <div key={data.name} className="row h-14 items-center text-white odd:bg-[#3E3E3E] md:h-12 lg:justify-end">
                <div className="col-6 lg:col-4">
                  <p className="font-light text-white">{data.name}</p>
                </div>

                <div className="col-6">
                  <p className="text-white">{data.value}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="text-white lg:col-6">
            {secondHalf.map((data) => (
              <div key={data.name} className="row h-14 items-center text-white odd:bg-[#3E3E3E] md:h-12 lg:justify-end">
                <div className="col-6 lg:col-4">
                  <p className="font-light text-white">{data.name}</p>
                </div>

                <div className="col-6">
                  <p className="text-white">{data.value}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnicalSpecifications;
