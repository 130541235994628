import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SliderSection from "./SliderSection";
import ProductCard from "./ProductCard";

/* TODO: Refactorizar para aceptar lista de productos */

const ProductSlider = () => {
  const data = useStaticQuery(graphql`
    query ProductSliderQuery {
      directus {
        products(filter: { category: { slug: { _eq: "tv" } } }, sort: "sort") {
          id
          name
          slug
          sliders(sort: "sort") {
            id
            directus_files_id {
              id
              imageFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          features_icons {
            id
            name
            image {
              id
              title
              imageFile {
                publicURL
              }
            }
            image_small {
              id
              title
              imageFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  const { products } = data.directus;

  const settings = {
    dots: true,
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    responsive: [{ breakpoint: 900, settings: { slidesToShow: 1 } }],
  };

  return (
    <SliderSection title="Estos productos te pueden interesar" className="py-10 md:py-16" sliderSettings={settings}>
      {products.map((product) => (
        <ProductCard
          name={product.name}
          image={product.sliders[0]}
          link={`/tv/${product.slug}`}
          features={product.features_icons}
        />
      ))}
    </SliderSection>
  );
};

export default ProductSlider;
