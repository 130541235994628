import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Slider from "react-slick";
import LeftArrowGreen from "../../assets/icons/left-arrow-green.inline.svg";

const SliderCarrousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [sliderFather, setSliderFather] = useState(null);
  const [sliderChild, setSliderChild] = useState(null);

  return (
    <div className="relative pt-16 md:pt-0">
      <div className="absolute right-4 top-4 z-10 rounded-2xl bg-[#F5F5F5] px-4 py-1 text-[#697276] md:right-6">
        {currentIndex + 1} de {images.length}
      </div>

      <Slider
        asNavFor={sliderChild}
        ref={(slider) => setSliderFather(slider)}
        arrows={false}
        beforeChange={(_, next) => setCurrentIndex(next)}
      >
        {images.map((image) => {
          const { title, imageFile } = image.directus_files_id;
          return (
            <div key={image.id} className="w-full">
              <div className="mx-auto w-fit">
                <GatsbyImage image={getImage(imageFile)} alt={title} className="mx-auto" />
              </div>
            </div>
          );
        })}
      </Slider>

      <div className="mx-auto flex justify-between gap-8 md:max-w-xl">
        <div className="hidden items-center md:flex">
          <button
            onClick={() => sliderChild.slickPrev()}
            className="inline-flex h-fit cursor-pointer items-center justify-center"
          >
            <LeftArrowGreen className="h-6 w-6" />
          </button>
        </div>

        <div className="w-full md:max-w-[75%]">
          <Slider
            asNavFor={sliderFather}
            ref={(slider) => setSliderChild(slider)}
            slidesToShow={6}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
            dots={false}
          >
            {images.map((image) => {
              const { title, imageFile } = image.directus_files_id;
              return (
                <div key={image.id}>
                  <GatsbyImage image={getImage(imageFile)} alt={title} />
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="hidden items-center md:flex">
          <button
            onClick={() => sliderChild.slickNext()}
            className="inline-flex h-fit cursor-pointer items-center justify-center"
          >
            <LeftArrowGreen className="h-6 w-6 rotate-180" />
          </button>
        </div>
      </div>

      <ul className="hidden w-full text-center md:block">
        {images.map((_, i) => (
          <li key={i} className="mx-[5px] inline-block h-fit">
            <button
              onClick={() => sliderChild.slickGoTo(i)}
              className={`h-[10px] w-[10px] rounded-full transition-colors duration-300 ${
                currentIndex === i ? "bg-[#00AAA6]" : "bg-[#D8D8D8]"
              }`}
            >
              <span className="sr-only">{i + 1}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SliderCarrousel;
