import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";

const ProductBanner = ({ content, image, imageOnTop, featureList }) => {
  return (
    <section className="mt-8 bg-[#303030]">
      <div className={`flex ${imageOnTop ? "flex-col-reverse" : "flex-col"}`}>
        <div className="container py-16">
          <div
            className="[&>h3]:text-3 text-center [&>*]:mx-auto [&>h3]:max-w-[641px] [&>h3]:!font-normal [&>h3]:text-white [&>p]:mt-8 [&>p]:max-w-[864px]"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>

        {image ? (
          <div className="h-full shrink-0">
            <GatsbyImage image={getImage(image.imageFile)} alt={image.title} className="h-full w-full" />
          </div>
        ) : null}
      </div>

      {featureList?.length > 0 && (
        <div className="container">
          <div className="pb-12 md:pb-20">
            <Slider
              speed={1000}
              slidesToShow={featureList.length}
              arrows={false}
              autoplay={true}
              responsive={[{ breakpoint: 900, settings: { slidesToShow: 1 } }]}
            >
              {featureList.map((item) => {
                if (!item.image) {
                  return null;
                }

                return (
                  <div key={item.title}>
                    <div className="mx-4 flex h-[224px] flex-col items-center justify-center rounded-2xl bg-white bg-opacity-[0.16] p-7">
                      <div className="mb-6 flex h-20 items-center">
                        <img src={item.image.imageFile.publicURL} alt={item.name} className="max-h-full" />
                      </div>
                      <span className="fotn-bold text-center text-base text-white">{item.name}</span>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      )}
    </section>
  );
};

export default ProductBanner;
