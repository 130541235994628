import React from "react";
import { Link } from "gatsby";

import ShareButton from "./ShareButton";
import SliderCarrousel from "./SliderCarrousel";
import ArrowBack from "@/assets/icons/arrow-back.inline.svg";
import tvSize from "../../assets/images/TvSize.svg";

const ProductHero = ({ backTo, name, features, retails, sliders, inches, inchesList }) => {
  return (
    <section className="container lg:mt-[80px]">
      <div className="row">
        <div className="relative !px-0 md:col-7 md:!px-4">
          <Link className="absolute left-4 top-4 z-10 flex items-center gap-2 font-bold" to={backTo}>
            <ArrowBack className="h-5 w-5" />
            Regresar
          </Link>
          <SliderCarrousel images={sliders} />
        </div>

        <div className="flex flex-col pt-6 md:col-5 md:flex-col-reverse md:justify-end md:pt-0">
          <div>
            <h1 className="text-4 mb-8 w-full max-w-[310px]">{name}</h1>

            {inchesList && inchesList.length > 1 ? (
              <div className="mb-4 flex w-full items-center gap-3 md:mb-8">
                <img src={tvSize} alt="" className="h-6 w-6" />

                <div className="flex flex-wrap gap-2">
                  {inchesList.map((i) => (
                    <Link
                      to={`/tv/${i.slug}`}
                      key={i.inch}
                      className={`h-10 px-4 flex items-center border rounded-full font-medium ${
                        inches === i.inch ? "border-[#00AAA6] text-[#00AAA6]" : " border-[#303030] text-[#303030]"
                      }`}
                    >
                      {i.inch}
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}

            <div className="mb-6">
              <p className="mb-4 border-b border-[#F5F5F5] pb-2 font-bold text-black">Características</p>
              <ul className="list-disc pl-6 text-[#00AAA6]">
                {features.split("\n").map((feature) => (
                  <li key={feature} className="mb-2 last:mb-0">
                    <span className="text-[#697276]">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-8">
              <p className="mb-6 border-b border-[#F5F5F5] pb-2 font-bold text-black">Encuéntralo en:</p>
              <div className="find-buttons flex flex-wrap gap-4">
                {retails?.length > 0 ? (
                  retails.map((retail, i) => (
                    <a
                      key={i}
                      onClick={() => {
                        if ("fbq" in window) {
                          window.fbq("trackCustom", "RetailButton", { retail: retail.name, product: name });
                        }
                      }}
                      className="btn"
                      href={retail.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {retail.name}
                    </a>
                  ))
                ) : (
                  <button type="button" className="btn" disabled>
                    Próximamente
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="flex justify-center border-t border-[#F5F5F5] py-4 md:justify-end  md:border-none md:pb-4 md:pt-10">
            <ShareButton />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductHero;
