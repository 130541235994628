import React from "react";

import { Layout } from "../layout/Layout";
import Seo from "../components/common/Seo";
import ReactPlayer from "react-player";
import ProductHero from "../components/common/ProductHero";
import ProductBanner from "../components/common/ProductBanner";
import FeatureGrid from "../components/common/FeatureGrid";
import TechnicalSpecifications from "../components/common/TechnicalSpecifications";
import ProductSlider from "../components/common/ProductSlider";

const Product = ({ pageContext }) => {
  const { product, backTo } = pageContext;
  const isTv = product.category.slug === "tv";

  return (
    <Layout pageId="product-detail">
      <ProductHero
        name={product.name}
        features={product.features}
        retails={product.stores}
        sliders={product.sliders}
        backTo={backTo}
        inches={product.inches}
        inchesList={product.listInches}
      />
      <ProductBanner
        content={product.banner_text}
        image={product.banner_image}
        featureList={product.features_icons}
        imageOnTop={isTv && product.features_icons?.length > 0}
      />
      {product.video && <ReactPlayer url={product.video} playing={true} width="100%" height="100%" />}
      <FeatureGrid items={product.banners.feature_banners} />
      {product.specs && <TechnicalSpecifications specs={product.specs} />}
      <ProductSlider />
    </Layout>
  );
};

export const Head = ({ pageContext }) => <Seo title={`${pageContext.product.name} | Hisense`} />;

export default Product;
