import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FeatureGrid = ({ items }) => {
  return (
    <section className="bg-[#303030]">
      {items.map((item) => {
        const { id, text, image } = item;
        return (
          <div key={id} className="row g-0 md:even:flex-row-reverse">
            <div className="lg:col-6">
              <GatsbyImage image={getImage(image.imageFile)} alt={image.title} className="w-full" />
            </div>

            <div className="flex items-center lg:col-6">
              <div className="mx-auto w-full max-w-xl px-4 py-8 text-center lg:py-0">
                {item.title && <p className="mb-2 text-center text-2xl font-bold text-[#00AAA6]">{item.title}</p>}

                <div
                  className="w-full max-w-[640px] text-center text-white [&>*]:mx-auto [&>h3]:mb-4 [&>h3]:text-3xl [&>h3]:lg:mb-8 [&>h3]:lg:text-[40px] [&>h3]:lg:leading-[48px]"
                  dangerouslySetInnerHTML={{ __html: text }}
                />

                {item.subtitle3 && <p className="mt-4 text-center text-[10px] text-[#697276]">{item.subtitle3}</p>}
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default FeatureGrid;
